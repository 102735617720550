:root {
	/* Padding */
	--padding-xs: 5px;
	--padding-s: 10px;
	--padding-m: 20px;
	--padding-l: 30px;
	--padding-16: 16px;
	--padding-12: 12px;

	/* Margin */
	--margin-xs: 5px;
	--margin-s: 10px;
	--margin-m: 20px;
	--margin-l: 30px;
	--margin-xl: 40px;
	--margin-xxl: 60px;

	/* Font size */
	--font-size-xxs: 0.7rem;
	--font-size-xs: 0.8rem;
	--font-size-s: 0.9rem;
	--font-size-m: 1rem;
	--font-size-l: 1.2rem;
	--font-size-xl: 1.6rem;

	/* Font weight */
	--fw-heavy: 700;
	--fw-normal: 400;
	--fw-light: 300;

	/* Borders */
	--border-solid-grey: 1px solid #edf2f4;

	/* Colors */
	--color-primary: #2E3B6A;
	--color-accent: #f00b51;
	--color-error: #f44336;
	--color-text-primary: #2e294e;
	--color-text-secondary: #8d99ae;
	--color-drawer-icon: #767ba0;
	--color-green-button: #1b998b;
}